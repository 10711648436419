import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import PrivyConfig from "../components/privyConfig";
import GlobalRenders from "../components/GlobalRenders";
import { Outlet } from "react-router-dom";
import CountdownChecker from "./CountdownChecker";
import { APIProvider } from "@vis.gl/react-google-maps";
import { googleMapApiKey } from "../constants";
import { Analytics } from "@vercel/analytics/react";

export default function GlobalProvider() {
  const queryClient = new QueryClient();
  return (
    <>
      <PrivyConfig>
        <QueryClientProvider client={queryClient}>
          <GlobalRenders>
            <CountdownChecker>
              <APIProvider apiKey={googleMapApiKey}>
                <Outlet />
              </APIProvider>
            </CountdownChecker>
          </GlobalRenders>
        </QueryClientProvider>
      </PrivyConfig>
      <Analytics />
    </>
  );
}
