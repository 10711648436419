// This hook will fetch the user from the database using the privyDid and privyJWT
// If user is not found, it will create a new user and return it

import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import usePrivyJWT from "./usePrivyJWT";
import axios from "axios";
import { DBUseeType } from "../types";
import { apiBaseURL } from "../constants";
import { useMemo } from "react";
import { GroupIdInfo } from "../constants/GroupIdInfo";

const useDBUser = (chipUid?: string) => {
  const { privyJWT, privyDid, privy } = usePrivyJWT();

  const { data, ...rest } = useQuery<DBUseeType>({
    queryKey: ["db-user", chipUid ?? privyDid],
    queryFn: async () => {
      let dbUser = await axios.get(`${apiBaseURL}/users`, {
        headers: {
          Authorization: `Bearer ${privyJWT}`,
        },
        params: {
          privyDid: chipUid ? undefined : privyDid,
          chipUid,
        },
      });

      if (dbUser?.data && dbUser?.data?.length > 0) {
        return dbUser?.data[0];
      } else if (!chipUid) {
        // create user and return it
        await axios.post(
          `${apiBaseURL}/users`,
          {},
          {
            headers: {
              Authorization: `Bearer ${privyJWT}`,
            },
          }
        );

        dbUser = await axios.get(`${apiBaseURL}/users`, {
          headers: {
            Authorization: `Bearer ${privyJWT}`,
          },
          params: {
            privyDid,
          },
        });
        return dbUser?.data[0];
      }
    },
    enabled: !!privyJWT && privy.ready && !!privy.user?.wallet,
    staleTime: 1000 * 60 * 60 * 24, // 24 hours
    gcTime: 1000 * 60 * 60 * 25, // 25 hours
    refetchInterval: 1000 * 60 * 60 * 23, // 23 hours
  });

  const ownedGroups = useMemo(() => data?.contacts.map((contact) => contact.receiver.chip.groupId), [data]);

  const treasureBadges = useMemo(
    () =>
      GroupIdInfo.map((badge) => {
        const contactDetails = data?.contacts.find((contact) => contact.receiver.chip.groupId === Number(badge.groupID));
        const isActive = ownedGroups?.includes(Number(badge.groupID));
        return {
          ...badge,
          _id: contactDetails?._id,
          type: "treasure",
          groupId: badge.groupID,
          isActive,
          title: isActive ? badge.activatedTitle : badge.inactivateTitle,
          timestamp: contactDetails?.createdAt,
          lotteryPrize: contactDetails?.lottery?.prize === "無" ? null : contactDetails?.lottery?.prize,
        };
      }).filter((badge) => badge.title !== "N/A"),
    [ownedGroups]
  );

  const invitationBadges = useMemo(() => {
    return (
      data?.contacts
        ?.filter((contact) => contact.receiver.email)
        ?.map((contact) => {
          return {
            ...contact,
            _id: contact?._id,
            type: "invitation",
            groupId: contact.receiver.chip.groupId,
            isActive: true,
            title: contact.receiver.username ?? contact.receiver.email,
            timestamp: contact.createdAt,
            lotteryPrize: contact.lottery?.prize === "無" ? null : contact.lottery?.prize,
          };
        }) ?? []
    );
  }, [data]);

  return useMemo(
    () => ({
      dbUser: data,
      userBadges: { treasureBadges, invitationBadges },
      ...rest,
    }),
    [data, treasureBadges, invitationBadges, rest]
  );
};

export const useUpdateDBUser = () => {
  const queryClient = useQueryClient();
  const { dbUser } = useDBUser();
  const { privyJWT } = usePrivyJWT();
  const mutation = useMutation({
    mutationKey: ["update-db-user"],
    mutationFn: async (userInfo: { phone: string; username: string; name: string }) => {
      const updatedResp = await axios.put(`${apiBaseURL}/users/${dbUser?._id}`, userInfo, {
        headers: {
          Authorization: `Bearer ${privyJWT}`,
        },
      });
      if (updatedResp?.data && updatedResp?.data?.length > 0) {
        return updatedResp?.data[0];
      }
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ["db-user"] });
      queryClient.invalidateQueries({ queryKey: ["leaderboard"] });
    },
  });

  return mutation;
};

export default useDBUser;
