import { useCallback, useEffect, useMemo, useState } from "react";
import ClickAwayListener from "react-click-away-listener";

// import { doc, setDoc } from "firebase/firestore";
// import { auth, db } from "../firebase";
import useGlobalStore from "../store/globalStore";
// import { signOut } from "firebase/auth";
import CustomButton from "./CustomButton";
import useDBUser, { useUpdateDBUser } from "../hooks/useDBUser";
import { usePrivy } from "@privy-io/react-auth";
import LoadingSpinner from "./LoadingSpinner";

const Profile = () => {
  const { dbUser: user, isPending: dbUserIsLoading } = useDBUser();
  const { mutateAsync: updateDBUser, isPending: updateDBUserIsPending } = useUpdateDBUser();

  const privy = usePrivy();
  // const user = useAuthStore((state) => state.user);
  // const setUser = useAuthStore((state) => state.setUser);
  const setIsLoading = useGlobalStore((state) => state.setIsLoading);
  const setProfileOpen = useGlobalStore((state) => state.setProfileOpen);

  const [showEditNickname, setShowEditNickname] = useState(false);
  const [showEditName, setShowEditName] = useState(false);
  const [showEditPhone, setShowEditPhone] = useState(false);

  const [editNicknameValue, setEditNicknameValue] = useState("");
  const [editNameValue, setEditNameValue] = useState("");
  const [editPhoneValue, setEditPhoneValue] = useState("");

  const editProfileInfo = useCallback((key: string, value: string) => {
    switch (key) {
      case "username":
        if (value.length > 20) {
          return;
        } else {
          setEditNicknameValue(value);
        }
        break;
      case "name":
        if (value.length > 20) {
          return;
        } else {
          setEditNameValue(value);
        }
        break;
      case "phone":
        // validate phone
        if (value.length > 10) {
          return;
        } else {
          setEditPhoneValue(value);
        }
        break;
    }
  }, []);

  useEffect(() => {
    setEditNicknameValue(user?.username || "");
    setEditNameValue(user?.name || "");
    setEditPhoneValue(user?.phone || "");
  }, [user]);

  const formatAddress = (address?: string) => {
    if (!address) return "";
    return `${address.slice(0, 6)}...${address.slice(-4)}`;
  };

  const showSaveButton = useMemo(() => {
    return (
      (editNicknameValue !== user?.username || editPhoneValue !== user?.phone || editNameValue !== user?.name) && !dbUserIsLoading && user
    );
  }, [editNicknameValue, editPhoneValue, editNameValue, user]);

  const updateProfileHandler = async () => {
    if (editNicknameValue === "" || editPhoneValue === "" || editNameValue === "") {
      alert("請填寫完整資訊");
      return;
    }

    if (editNicknameValue.length < 3) {
      alert("用戶暱稱需多於3個字元");
      return;
    }

    if (editPhoneValue.length < 10) {
      alert("手機號碼格式不正確");
      return;
    }

    // update profile logic here
    try {
      if (!user?.privyDid) return;
      setIsLoading(true);
      console.log(user);
      await updateDBUser({ username: editNicknameValue, phone: editPhoneValue, name: editNameValue });
      setIsLoading(false);
    } catch (e) {
      setIsLoading(false);
      // alert("An error occurred");
      console.log(e);
    }
  };
  // const navigate = useNavigate();
  // // const signOutHandler = async () => {
  // //   try {
  // //     setIsLoading(true);
  // //     await privy.logout();
  // //     // await signOut(auth);
  // //     // setUser(null);
  // //     setIsLoading(false);
  // //     setProfileOpen(false);
  // //     navigate("/");
  // //   } catch (e) {
  // //     setIsLoading(false);
  // //     alert("An error occurred");
  // //     console.log(e);
  // //   }
  // // };

  return (
    <>
      <div className="p-8 flex flex-col gap-8 h-[460px] xsMobile:h-[700px] overflow-y-scroll">
        <div className="flex justify-between items-center">
          <h3 className="text-2xl font-semibold">個人資訊</h3>
          <img
            src="/cross.svg"
            alt="artichoke menu"
            width={20}
            height={20}
            className="object-contain"
            onClick={() => setProfileOpen(false)}
          />
        </div>
        <div className="flex flex-col gap-1">
          <label className="text-gray-400">電子郵件</label>
          <p>{user?.email}</p>
        </div>

        <div className="flex flex-col gap-1">
          <label className="text-gray-400">錢包地址</label>
          <p>{formatAddress(privy.user?.wallet?.address)}</p>
        </div>

        <div className="flex flex-col gap-1">
          <label className="text-gray-400">用戶暱稱</label>
          {!showEditNickname && (
            <div className="flex gap-5">
              <p>{!editNicknameValue ? "尚未設定" : editNicknameValue}</p>
              <img
                src="/edit.svg"
                alt="edit"
                width={24}
                height={24}
                onClick={() => setShowEditNickname(true)}
                className="cursor-pointer hover:opacity-80 active:opacity-90 active:translate-y-0.5"
              />
            </div>
          )}
          {showEditNickname && (
            <ClickAwayListener onClickAway={() => setShowEditNickname(false)}>
              <input
                type="text"
                placeholder="Enter username"
                className="border border-gray-300 rounded-md p-2"
                value={editNicknameValue}
                onChange={(e) => editProfileInfo("username", e.target.value)}
              />
            </ClickAwayListener>
          )}
        </div>

        <div className="flex flex-col gap-1">
          <label className="text-gray-400">用戶姓名</label>
          {!showEditName && (
            <div className="flex gap-5">
              <p>{!editNameValue ? "尚未設定" : editNameValue}</p>
              <img
                src="/edit.svg"
                alt="edit"
                width={24}
                height={24}
                onClick={() => setShowEditName(true)}
                className="cursor-pointer hover:opacity-80 active:opacity-90 active:translate-y-0.5"
              />
            </div>
          )}
          {showEditName && (
            <ClickAwayListener onClickAway={() => setShowEditName(false)}>
              <input
                type="text"
                placeholder="Enter name"
                className="border border-gray-300 rounded-md p-2"
                value={editNameValue}
                onChange={(e) => editProfileInfo("name", e.target.value)}
              />
            </ClickAwayListener>
          )}
        </div>

        <div className="flex flex-col gap-1">
          <label className="text-gray-400">手機號碼</label>
          {!showEditPhone && (
            <div className="flex gap-5">
              <p>{!editPhoneValue ? "尚未設定" : editPhoneValue}</p>
              <img
                src="/edit.svg"
                alt="edit"
                width={24}
                height={24}
                onClick={() => setShowEditPhone(true)}
                className="cursor-pointer hover:opacity-80 active:opacity-90 active:translate-y-0.5"
              />
            </div>
          )}
          {showEditPhone && (
            <ClickAwayListener onClickAway={() => setShowEditPhone(false)}>
              <input
                type="text"
                placeholder="Enter new phone number"
                className="border border-gray-300 rounded-md p-2"
                value={editPhoneValue}
                onChange={(e) => editProfileInfo("phone", e.target.value)}
              />
            </ClickAwayListener>
          )}
        </div>

        <div className="flex gap-5 justify-center">
          {showSaveButton && (
            <>
              <CustomButton onClick={updateProfileHandler} className="!bg-[#5F5F5F] border border-white !h-12 !text-white !text-base">
                <div className="flex justify-center gap-2 w-full text-center">{updateDBUserIsPending ? <LoadingSpinner /> : "儲存"}</div>
              </CustomButton>
            </>
          )}
        </div>
      </div>
    </>
  );
};

export default Profile;
