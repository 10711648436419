import dayjs from "dayjs";

export const MILLION = 1000000;

export const NAVIGATION = [
  {
    id: 1,
    title: "首頁",
    link: "/",
  },
  {
    id: 2,
    title: "AP X MUSIC & ROO",
    link: "/about",
  },
  {
    id: 3,
    title: "活動資訊",
    link: "/event/1",
  },
  {
    id: 4,
    title: "互動體驗《漸變之環》",
    link: "/profile",
  },
  {
    id: 5,
    title: "AR 體驗",
    link: "/#ar",
  },
  {
    id: 6,
    title: "聯絡我們",
    link: "/contact",
  },
];

export const GOOGLE_MAP_ID = "20719575febbf4f";

const eventStartTime = dayjs("2024-09-28T13:00:00.000+08:00");
export const isEventStarted = +new Date() >= +eventStartTime;
