import { optimism, optimismSepolia } from "viem/chains";

export const rpcURL = import.meta.env.VITE_PUBLIC_RPC_URL || "https://sepolia.optimism.io";
export const contractAddress = import.meta.env.VITE_PUBLIC_CONTRACT_ADDRESS || "0xf965281ACFb9fa0d1F761523eB1BcF4Eb1C13F05";
export const apiBaseURL = import.meta.env.VITE_API_BASE_URL || "https://ap-arcade-api-git-dev-elysium.vercel.app/api";
export const authBaseURL = import.meta.env.VITE_PUBLIC_AUTH_BASE_URL || "https://auth-dev.phys.link";
export const contractChainID = import.meta.env.VITE_PUBLIC_CONTRACT_CHAIN_ID || "11155420";
export const useMockAuthAndOwner = import.meta.env.VITE_PUBLIC_USE_MOCK_AUTH_AND_OWNER;

export const contractChainType = import.meta.env.VITE_PUBLIC_CONTRACT_CHAIN || "sepolia";

export const chain = contractChainType === "sepolia" ? optimismSepolia : optimism;
const alchemyChain = contractChainType === "sepolia" ? "opt-sepolia" : "opt-mainnet";
const alchemyEndpoint = "https://{chain}.g.alchemy.com/v2"?.replace("{chain}", alchemyChain);

export const chainRpcUrl = `${alchemyEndpoint}/Hgqdoks5CoO6dbNmQ9ODGlbLjRXQgymo/`;
export const alchemyNftApiEndpoint = chainRpcUrl.replace("v2", "nft/v3");

export const googleMapApiKey = import.meta.env.VITE_PUBLIC_GOOGLE_MAP_API_KEY || "";

export const hCaptchaSiteKey = import.meta.env.VITE_PUBLIC_HCAPTCHA_SITE_KEY || "d474b472-d406-44b0-a378-60ee5fc8f128";
export const formSparkUrl = import.meta.env.VITE_PUBLIC_FORM_SPARK_FORM_URL || "https://submit-form.com/0lDitDHGO";
