import Navbar from "../components/Navbar";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { useNavigate, Outlet } from "react-router-dom";
import ScrollToTop from "../components/ScrollToTop";
import { useEffect } from "react";
import Footer from "../components/Footer";
import { useLayoutEffect, useRef } from "react";
import useGlobalStore from "../store/globalStore";

const MainLayout = () => {
  const isDesktop = window.innerWidth > 768;
  const navigate = useNavigate();

  const { setTooltipDom } = useGlobalStore();
  const tooltipDom = useRef<HTMLDivElement>(null);

  useLayoutEffect(() => {
    if (tooltipDom.current) {
      setTooltipDom(tooltipDom.current);
    }
  }, [tooltipDom]);

  useEffect(() => {
    if (isDesktop) {
      navigate("/desktop");
    }
  }, [isDesktop, navigate]);

  return (
    <div>
      <div ref={tooltipDom}></div>
      <ScrollToTop />
      <Navbar />
      <div className="min-h-screen pt-[70px]">
        <div className="relative z-10 mb-[400px]">
          <div className="bg-black">
            <Outlet></Outlet>
          </div>
        </div>
        <Footer />
      </div>
    </div>
  );
};

export default MainLayout;
