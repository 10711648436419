export const GroupIdInfo = [
  {
    groupID: 7,
    inactivateTitle: "愛彼 A3\n微風信義店",
    activatedTitle: "愛彼 A3\n微風信義店",
    embededGoogleMapLink:
      "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3614.809317285333!2d121.5667909!3d25.040544399999998!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3442abbbeae9fac3%3A0x8e3b4fbfff4520b1!2zMTEw5Y-w54Gj5Y-w5YyX5biC5L-h576p5Y2A5b-g5a2d5p2x6Lev5LqU5q61NjjomZ8!5e0!3m2!1szh-TW!2shk!4v1724244809440!5m2!1szh-TW!2shk",
    lat: 25.040544,
    lng: 121.56679,
    googleMapLink: "https://maps.app.goo.gl/JztX9yW18x8RK2cH9",
  },
  {
    groupID: 8,
    inactivateTitle: "愛彼 A13\n遠百信義店",
    activatedTitle: "愛彼 A13\n遠百信義店",
    embededGoogleMapLink:
      "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3614.9174996084416!2d121.5679503!3d25.036873699999997!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3442abb6c27173db%3A0xca9bcd7d27aae9d1!2sAudemars%20Piguet%20Boutique%20Taipei%20A13!5e0!3m2!1szh-TW!2shk!4v1724244837846!5m2!1szh-TW!2shk",
    lat: 25.036873699999997,
    lng: 121.5679503,
    googleMapLink: "https://maps.app.goo.gl/C7L2kaRJA2eh52iN9",
  },
  {
    groupID: 9,
    inactivateTitle: "FAKE SOBER TAIPEI 2 店",
    activatedTitle: "FAKE SOBER TAIPEI 2 店",
    embededGoogleMapLink:
      "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3614.966668486852!2d121.56745029999998!3d25.035205199999996!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3442ab839713726d%3A0x830df9a4a46a72dd!2sFake%20Sober%20Taipei!5e0!3m2!1szh-TW!2shk!4v1724244853313!5m2!1szh-TW!2shk",
    lat: 25.03531213,
    lng: 121.5673748,
    googleMapLink: "https://maps.app.goo.gl/kskGpySjfkDXwmzx7",
  },
  {
    groupID: 10,
    inactivateTitle: "WTNC",
    activatedTitle: "WTNC",
    embededGoogleMapLink:
      "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3614.963580235182!2d121.5660665!3d25.03531!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3442ab00008eaf99%3A0x1cdcef3955132e98!2sWTNC!5e0!3m2!1szh-TW!2shk!4v1724244878058!5m2!1szh-TW!2shk",
    lat: 25.0353586,
    lng: 121.5660987,
    googleMapLink:
      "https://www.google.com/maps/place/WTNC/@25.0353148,121.5634916,17z/data=!3m2!4b1!5s0x3442abb0b674be35:0x8bfeff83b1d6f007!4m6!3m5!1s0x3442ab00008eaf99:0x1cdcef3955132e98!8m2!3d25.03531!4d121.5660665!16s%2Fg%2F11vwffjzfs?entry=ttu",
  },
  {
    groupID: 11,
    inactivateTitle: "統一時代百貨 夢廣場",
    activatedTitle: "統一時代百貨 夢廣場",
    embededGoogleMapLink:
      "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3614.8063256816636!2d121.56508819999998!3d25.0406459!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3442abe000453361%3A0x283f37f69b66b1ac!2z57Wx5LiA5pmC5Luj55m-6LKoIOWkouW7o-WgtA!5e0!3m2!1szh-TW!2shk!4v1724244890316!5m2!1szh-TW!2shk",
    lat: 25.0406507,
    lng: 121.5625133,
    googleMapLink:
      "https://www.google.com/maps/place/%E7%B5%B1%E4%B8%80%E6%99%82%E4%BB%A3%E7%99%BE%E8%B2%A8+%E5%A4%A2%E5%BB%A3%E5%A0%B4/@25.0406507,121.5625133,17z/data=!3m2!4b1!5s0x36ef31d83d17a355:0x7fc40101c3eaa2f!4m6!3m5!1s0x3442abe000453361:0x283f37f69b66b1ac!8m2!3d25.0406459!4d121.5650882!16s%2Fg%2F11stnyq139?entry=ttu",
  },
  {
    groupID: 12,
    inactivateTitle: "臺虎啜飲室",
    activatedTitle: "臺虎啜飲室",
    embededGoogleMapLink:
      "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3614.8180827661367!2d121.56677300000001!3d25.040246999999997!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3442abbbc0632e07%3A0x5836c1f514fd0ff4!2z5ZWc6aOy5a6k!5e0!3m2!1szh-TW!2shk!4v1724244906870!5m2!1szh-TW!2shk",
    lat: 25.0402518,
    lng: 121.5641981,
    googleMapLink:
      "https://www.google.com/maps/place/%E5%95%9C%E9%A3%B2%E5%AE%A4/@25.0402518,121.5641981,17z/data=!3m2!4b1!5s0x3442abc6ea5040a3:0x8a651582bdd48b17!4m6!3m5!1s0x3442abbbc0632e07:0x5836c1f514fd0ff4!8m2!3d25.040247!4d121.566773!16s%2Fg%2F11cm05ybr4?entry=ttu",
  },
  {
    groupID: 13,
    inactivateTitle: "KONDO BAR",
    activatedTitle: "KONDO BAR",
    embededGoogleMapLink:
      "https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d3615.201788005087!2d121.5407114!3d25.0272252!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3442aa2c0cffdd3d%3A0xd093bb22e34d5b0a!2zMTA25Y-w54Gj5Y-w5YyX5biC5aSn5a6J5Y2A5b6p6IiI5Y2X6Lev5LqM5q61MTg26Jmf!5e0!3m2!1szh-TW!2shk!4v1724244923030!5m2!1szh-TW!2shk",
    lat: 25.0405492,
    lng: 121.564216,
    googleMapLink:
      "https://www.google.com/maps/place/Kondo+bar/@25.0405492,121.564216,17z/data=!4m6!3m5!1s0x3442aba8760fdf13:0x34da7ba48017cbef!8m2!3d25.0272204!4d121.5432863!16s%2Fg%2F11vc2zs6sf?entry=ttu",
  },
  {
    groupID: 14,
    inactivateTitle: "9/7 限定快閃",
    activatedTitle: "9/7 限定快閃",
  },
  {
    groupID: 15,
    inactivateTitle: "N/A",
    activatedTitle: "9/7 限定快閃",
  },
  {
    groupID: 16,
    inactivateTitle: "RED",
    activatedTitle: "RED",
    mapSrc: "/images/map/map-red.jpg",
  },
  {
    groupID: 17,
    inactivateTitle: "ORANGE",
    activatedTitle: "ORANGE",
    mapSrc: "/images/map/map-orange.jpg",
  },
  {
    groupID: 18,
    inactivateTitle: "YELLOW",
    activatedTitle: "YELLOW",
    mapSrc: "/images/map/map-yellow.jpg",
  },
  {
    groupID: 19,
    inactivateTitle: "GREEN",
    activatedTitle: "GREEN",
    mapSrc: "/images/map/map-green.jpg",
  },
  {
    groupID: 20,
    inactivateTitle: "BLUE",
    activatedTitle: "BLUE",
    mapSrc: "/images/map/map-blue.jpg",
  },
  {
    groupID: 21,
    inactivateTitle: "PURPLE",
    activatedTitle: "PURPLE",
    mapSrc: "/images/map/map-purple.jpg",
  },
];
