import { useEffect } from "react";
import { allowedChipUid } from "../constants/beta";
import useChipJWT from "../hooks/useChipJWT";
import useGlobalStore from "../store/globalStore";
import useDBUser from "../hooks/useDBUser";
import { usePrivy } from "@privy-io/react-auth";
import { redirect } from "react-router-dom";
import LoadingSpinner from "../components/LoadingSpinner";
import CountdownDisplay from "../components/Countdown";

interface CountdownCheckerProps {
  children?: React.ReactNode;
}

export default function CountdownChecker({ children }: CountdownCheckerProps) {
  const { isSiteEnabled, setSiteEnabled } = useGlobalStore();
  const { isChipDetected, chipUID } = useChipJWT();
  const normalizedChipUID = chipUID?.toUpperCase();

  const { ready, authenticated } = usePrivy();
  const { isLoading, dbUser } = useDBUser();

  useEffect(() => {
    if (isSiteEnabled) return;

    if (isChipDetected && allowedChipUid.includes(normalizedChipUID)) {
      console.log("Enable site because whitelisted chip tapped");
      setSiteEnabled(true);
    }

    if (isChipDetected && !allowedChipUid.includes(normalizedChipUID)) {
      redirect("/countdown");
      return;
    }

    if (!ready || isLoading) return;

    const allowedContact = dbUser?.contacts.find((contact) => allowedChipUid.includes(contact?.receiver?.chip?.uid?.toUpperCase?.()));
    if (allowedContact) {
      console.log("Enable site because whitelisted chip claimed");
      setSiteEnabled(true);
    }
  }, [isSiteEnabled, dbUser, isChipDetected, normalizedChipUID, ready, isLoading, authenticated, setSiteEnabled]);

  if (isLoading || !ready) {
    return (
      <div className="w-screen h-screen flex items-center justify-center">
        <LoadingSpinner></LoadingSpinner>
      </div>
    );
  }

  if (!isSiteEnabled) {
    return <CountdownDisplay />;
  }

  return children;
}
