import { enableTime } from "../constants/beta";
import { SyncTheBeatLogo } from "../svg";
import Countdown from "react-countdown";

export default function CountdownDisplay() {
  const date = enableTime;
  console.log(date);

  return (
    <div className="top-0 left-0 w-full min-h-screen p-[16px] flex flex-col justify-center">
      <video
        poster="/images/login-bg.webp"
        autoPlay
        playsInline
        muted
        loop
        className="fixed -z-10 w-screen h-screen top-0 left-0 object-cover"
      >
        <source src="/images/login-bg.webm" type="video/webm" />
        <source src="/images/login-bg.mp4" type="video/mp4" />
      </video>
      <div className="bg-black rounded-[20px] px-6 py-8">
        <div className="flex flex-col items-center justify-center space-y-2">
          <img src="/images/home/ap-x-music.svg" alt="ap-x-music" width={329} height={54} className="mx-auto max-w-full" />
          <SyncTheBeatLogo></SyncTheBeatLogo>
        </div>
        <div className="pt-8">
          <Countdown
            date={date}
            renderer={({ days, hours, minutes, seconds }) => (
              <div className="text-center">
                <p className="text-lg tracking-widest">距離《離岸尋寶》還有</p>
                <ul>
                  <li className="inline-block p-4">
                    <span className="block text-4xl">{days}</span>天
                  </li>
                  <li className="inline-block p-4">
                    <span className="block text-4xl">{hours}</span>時
                  </li>
                  <li className="inline-block p-4">
                    <span className="block text-4xl">{minutes}</span>分
                  </li>
                  <li className="inline-block p-4">
                    <span className="block text-4xl">{seconds}</span>秒
                  </li>
                </ul>
              </div>
            )}
          ></Countdown>
        </div>
      </div>
    </div>
  );
}
