// This hook verifies the chip using the auth sdk and returns the chipJWT

import { useSearchParams } from "react-router-dom";
import { ChipAuthSdk } from "@ctor-lab/chip-auth-sdk";
import { useQuery } from "@tanstack/react-query";
import { authBaseURL } from "../constants";
import { ChipIdKey } from "../utils/chip";

const useChipJWT = () => {
  const [searchParams] = useSearchParams();
  const uid = searchParams.get("uid");
  const ctr = searchParams.get("ctr");
  const enc = searchParams.get("enc");
  const cmac = searchParams.get("cmac");

  const queryResult = useQuery({
    queryKey: ["chip-jwt"],
    queryFn: async () => {
      if (!uid || !ctr || !enc || !cmac) throw new Error("invalid_url");
      const sdk = new ChipAuthSdk({ apiBaseUrl: authBaseURL });
      await sdk.auth(uid, parseInt(ctr, 16), cmac, { enc });
      return {
        ...sdk,
        chipUID: uid,
      };
    },
    enabled: !!uid && !!ctr && !!enc && !!cmac,
    staleTime: Infinity,
    gcTime: Infinity,
    refetchInterval: false,
    refetchIntervalInBackground: false,
    retry: 0,
  });

  return {
    chipJWT: queryResult?.data?.jwtSignable,
    chipUID: (uid ?? queryResult?.data?.chipUID?.toUpperCase()) as ChipIdKey,
    ...queryResult,
    isChipDetected: uid || ctr || enc || cmac,
  };
};

export default useChipJWT;
