import { BeatLoader } from "react-spinners";
import useGlobalStore from "../store/globalStore";

const GlobalRenders = ({ children }: { children: React.ReactNode }) => {
  const isLoading = useGlobalStore((state) => state.isLoading);
  return (
    <>
      {isLoading && (
        <div className="fixed inset-0 bg-black bg-opacity-50 z-50 flex justify-center items-center">
          <BeatLoader color="#fff" size={15} />
        </div>
      )}
      {children}
    </>
  );
};

export default GlobalRenders;
