import { useEffect, useMemo } from "react";
import { Link, useNavigate } from "react-router-dom";
import Sidebar from "./Sidebar";
import classNames from "classnames";
import useGlobalStore from "../store/globalStore";
import { Tooltip } from "react-tooltip";
import Profile from "./Profile";
import Information from "./Information";
import useDBUser from "../hooks/useDBUser";
import { usePrivy } from "@privy-io/react-auth";
import { createPortal } from "react-dom";

const Navbar = () => {
  const { isSidebarOpen, isProfileOpen, isInformationOpen, setTooltipOpen, setSidebarOpen, setProfileOpen, tooltipDom } = useGlobalStore();

  const { ready, authenticated } = usePrivy();

  const { dbUser, isLoading } = useDBUser();
  const navigate = useNavigate();

  const isTooltipOpen = useMemo(() => {
    return !isSidebarOpen && !isProfileOpen && !isLoading && (!dbUser?.phone || !dbUser.phone) && ready && authenticated;
  }, [dbUser, isLoading, ready, authenticated, isSidebarOpen, isProfileOpen]);

  useEffect(() => {
    if (isSidebarOpen || isProfileOpen || isInformationOpen) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "auto";
    }
  }, [isSidebarOpen, isProfileOpen, isInformationOpen]);

  return (
    <div>
      <div className="fixed top-0 z-50 w-full h-[70px] max-h-[70px] bg-black px-8 flex justify-between items-center border-b border-ghost-white">
        <Link to="/" className="text-white text-lg">
          <img src="/logo.svg" alt="logo" width={33} height={30} />
        </Link>
        <div className="flex items-center gap-5">
          <p
            data-tooltip-id="my-tooltip"
            onMouseEnter={() => {
              setTooltipOpen(true);
            }}
            onMouseLeave={() => {
              setTooltipOpen(false);
            }}
            onClick={() => {
              if (ready && !authenticated) {
                navigate("/login");
                return;
              }
              setProfileOpen(true);
              if (isSidebarOpen) {
                setTimeout(() => {
                  setSidebarOpen(false);
                }, 400);
              }
            }}
          >
            <img src="/user.svg" alt="search" width={24} height={24} />
          </p>

          {tooltipDom &&
            createPortal(
              <Tooltip
                id="my-tooltip"
                content="請填寫完整 用戶資訊"
                isOpen={isTooltipOpen}
                style={{
                  backgroundColor: "white",
                  color: "#000",
                  padding: "10px",
                  fontSize: "12px",
                  textAlign: "center",
                  fontWeight: "normal",
                  width: "85px",
                  opacity: 1,
                  boxShadow: "0px 4px 4px 0px #00000040",
                  borderRadius: "5px",
                  zIndex: 10001,
                }}
                positionStrategy="fixed"
              />,
              tooltipDom
            )}

          <div className="w-8 h-8 flex justify-center items-center">
            {isSidebarOpen ? (
              <img
                src="/cross.svg"
                alt="artichoke menu"
                width={20}
                height={20}
                className="object-contain"
                onClick={() => setSidebarOpen(false)}
              />
            ) : (
              <img
                src="/menu.svg"
                alt="search"
                width={24}
                height={24}
                className="object-contain"
                onClick={() => {
                  setSidebarOpen(true);
                  setTimeout(() => {
                    setProfileOpen(false);
                  }, 400);
                }}
              />
            )}
          </div>
        </div>
      </div>
      <div className={classNames("sidebar", { open: isProfileOpen, close: !isProfileOpen })}>{isProfileOpen ? <Profile /> : null}</div>
      <div className={classNames("sidebar", { open: isSidebarOpen, close: !isSidebarOpen })}>{isSidebarOpen ? <Sidebar /> : null}</div>
      <Information />
    </div>
  );
};

export default Navbar;
