import { PrivyProvider } from "@privy-io/react-auth";

const PrivyConfig = ({ children }: { children: React.ReactNode }) => {
  return (
    <PrivyProvider
      appId="cls4zc09k005hjm0o73fvex5h"
      config={{
        // Display email and wallet as login methods
        loginMethods: ["email"],
        // Customize Privy's appearance in your app
        appearance: {
          // theme: "light",
          accentColor: "#676FFF",
          // logo: "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRhOEK6JTSVp_tzed4VLXaKqnvOozi_loDOkg&s",
        },
        // Create embedded wallets for users who don't have a wallet
        embeddedWallets: {
          createOnLogin: "users-without-wallets",
        },
      }}
    >
      {children}
    </PrivyProvider>
  );
};

export default PrivyConfig;
