import { Link, useLocation } from "react-router-dom";
import { NAVIGATION } from "../constants/static";
import classNames from "classnames";
import useGlobalStore from "../store/globalStore";

const Sidebar = () => {
  const { pathname, hash } = useLocation();
  const actualPath = `${pathname}${hash}`;
  const { setSidebarOpen } = useGlobalStore();

  return (
    <div className="poppins">
      {NAVIGATION.map((item) => (
        <Link
          to={item.link}
          key={item.id}
          className={classNames("text-[#777E90] text-2xl font-semibold block py-4 px-8", {
            "!text-ghost-white border-l-2 border-ghost-white": actualPath === item.link,
          })}
          onClick={() => {
            setSidebarOpen(false);
          }}
        >
          {item.title}
        </Link>
      ))}
    </div>
  );
};

export default Sidebar;
