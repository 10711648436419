import { create } from "zustand";
import { enableTime } from "../constants/beta";

type GlobalStoreType = {
  isLoading: boolean;
  setIsLoading: (isLoaded: boolean) => void;
  isSidebarOpen: boolean;
  setSidebarOpen: (isOpen: boolean) => void;
  isTooltipOpen: boolean;
  setTooltipOpen: (isOpen: boolean) => void;
  isProfileOpen: boolean;
  setProfileOpen: (isOpen: boolean) => void;
  isInformationOpen: boolean;
  setInformationOpen: (isOpen: boolean) => void;
  isLotteryDialogOpen: boolean;
  contactId: string | null;
  setLotteryDialogOpen: (isOpen: boolean) => void;
  setContactId: (contactId: string | null) => void;

  isSiteEnabled: boolean;
  setSiteEnabled: (isSiteEnabled: boolean) => void;

  tooltipDom: HTMLDivElement | null;
  setTooltipDom: (tooltipDom: HTMLDivElement | null) => void;
};

const useGlobalStore = create<GlobalStoreType>()((set) => ({
  isLoading: false,
  isSidebarOpen: false,
  isTooltipOpen: false,
  isProfileOpen: false,
  isInformationOpen: false,
  isLotteryDialogOpen: false,
  contactId: null,
  setIsLoading: (isLoaded) => set({ isLoading: isLoaded }),
  setSidebarOpen: (isOpen) => set({ isSidebarOpen: isOpen }),
  setTooltipOpen: (isOpen) => set({ isTooltipOpen: isOpen }),
  setProfileOpen: (isOpen) => set({ isProfileOpen: isOpen }),
  setInformationOpen: (isOpen) => set({ isInformationOpen: isOpen }),
  setLotteryDialogOpen: (isOpen) => set({ isLotteryDialogOpen: isOpen }),
  setContactId: (contactId) => set({ contactId: contactId }),

  isSiteEnabled: +new Date() >= +enableTime,
  // isSiteEnabled: true,
  setSiteEnabled: (isSiteEnabled) => set({ isSiteEnabled: isSiteEnabled }),

  tooltipDom: null,
  setTooltipDom: (tooltipDom) => set({ tooltipDom: tooltipDom }),
}));

export default useGlobalStore;
