import { useEffect } from "react";
import useChipJWT from "../hooks/useChipJWT";
import { Outlet, useNavigate } from "react-router-dom";

export const ChipChecker = () => {
  const { isLoading, error, chipJWT, isChipDetected } = useChipJWT();
  const navigate = useNavigate();

  useEffect(() => {
    if (isLoading) return;
    if (error) navigate("/unauthorized");
    if (isChipDetected && chipJWT) navigate("/mint");
  }, [isLoading, error]);

  if (isChipDetected) {
    return <></>;
  }

  return <Outlet></Outlet>;
};
