// This hook gets accessToken from privy that can be used to call the API

import { usePrivy } from "@privy-io/react-auth";
import { useQuery } from "@tanstack/react-query";

const usePrivyJWT = () => {
  const { getAccessToken, ready, user } = usePrivy();
  const { data, ...rest } = useQuery({
    queryKey: ["access-token", user?.id],
    queryFn: async () => {
      return await getAccessToken();
    },
    enabled: ready && user !== null, // user is null when not authenticated
    staleTime: 1000 * 60 * 60 * 24, // 24 hours
    gcTime: 1000 * 60 * 60 * 25, // 25 hours
    refetchInterval: 1000 * 60 * 60 * 23, // 23 hours
  });

  return {
    privyJWT: data,
    privyDid: user?.id,
    ...rest,
    privy: {
      ready,
      user,
    },
  };
};

export default usePrivyJWT;
