import { SvgParamTypes } from "./types";

export const UncheckedIcon = ({ onClick }: Partial<SvgParamTypes>) => (
  <svg xmlns="http://www.w3.org/2000/svg" width="21" height="20" viewBox="0 0 21 20" fill="none" onClick={onClick}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M4.34583 2C3.42853 2 3.20418 2.04332 2.97793 2.16433C2.83758 2.23939 2.73939 2.33758 2.66433 2.47793C2.54332 2.70418 2.5 2.92853 2.5 3.84583V16.1542C2.5 17.0715 2.54332 17.2958 2.66433 17.5221C2.73939 17.6624 2.83758 17.7606 2.97793 17.8357C3.20418 17.9567 3.42853 18 4.34583 18H16.6542C17.5715 18 17.7958 17.9567 18.0221 17.8357C18.1624 17.7606 18.2606 17.6624 18.3357 17.5221C18.4567 17.2958 18.5 17.0715 18.5 16.1542V3.84583C18.5 2.92853 18.4567 2.70418 18.3357 2.47793C18.2606 2.33758 18.1624 2.23939 18.0221 2.16433C17.7958 2.04332 17.5715 2 16.6542 2H4.34583ZM4.34583 0H16.6542C17.9915 0 18.4764 0.139238 18.9653 0.400699C19.4542 0.66216 19.8378 1.04584 20.0993 1.53473C20.3608 2.02362 20.5 2.50855 20.5 3.84583V16.1542C20.5 17.4915 20.3608 17.9764 20.0993 18.4653C19.8378 18.9542 19.4542 19.3378 18.9653 19.5993C18.4764 19.8608 17.9915 20 16.6542 20H4.34583C3.00855 20 2.52362 19.8608 2.03473 19.5993C1.54584 19.3378 1.16216 18.9542 0.900699 18.4653C0.639238 17.9764 0.5 17.4915 0.5 16.1542V3.84583C0.5 2.50855 0.639238 2.02362 0.900699 1.53473C1.16216 1.04584 1.54584 0.66216 2.03473 0.400699C2.52362 0.139238 3.00855 0 4.34583 0Z"
      fill="#C9CED6"
    />
  </svg>
);

export const HamburgIcon = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="18" height="12" viewBox="0 0 18 12" fill="none">
    <path d="M1 1.42859H17M1 6.00002H17M1 10.5714H7.85714" stroke="white" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
  </svg>
);

export const ArrowLeft = ({ fill }: Partial<SvgParamTypes>) => (
  <svg xmlns="http://www.w3.org/2000/svg" width="15" height="9" viewBox="0 0 15 9" fill="none">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M5.40906 8.7348C5.00324 9.10941 4.37058 9.0841 3.99597 8.67829L0.765201 5.17832C0.411604 4.79527 0.4116 4.20483 0.765194 3.82176L3.99597 0.321725C4.37057 -0.0840977 5.00323 -0.109407 5.40905 0.265194C5.81487 0.639796 5.84018 1.27245 5.46558 1.67828L3.78397 3.50004L13.5 3.50004C14.0523 3.50004 14.5 3.94776 14.5 4.50004C14.5 5.05232 14.0523 5.50004 13.5 5.50004L3.784 5.50004L5.46557 7.32172C5.84018 7.72754 5.81487 8.36019 5.40906 8.7348Z"
      fill={fill || "white"}
    />
  </svg>
);

export const ArrowRight = ({ fill }: Partial<SvgParamTypes>) => (
  <svg xmlns="http://www.w3.org/2000/svg" width="15" height="9" viewBox="0 0 15 9" fill="none">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M9.59094 8.7348C9.99676 9.10941 10.6294 9.0841 11.004 8.67829L14.2348 5.17832C14.5884 4.79527 14.5884 4.20483 14.2348 3.82176L11.004 0.321725C10.6294 -0.0840977 9.99677 -0.109407 9.59095 0.265194C9.18513 0.639796 9.15982 1.27245 9.53442 1.67828L11.216 3.50004L1.5 3.50004C0.947714 3.50004 0.499999 3.94776 0.499999 4.50004C0.499999 5.05232 0.947714 5.50004 1.5 5.50004L11.216 5.50004L9.53443 7.32172C9.15982 7.72754 9.18513 8.36019 9.59094 8.7348Z"
      fill={fill || "#777E91"}
    />
  </svg>
);

export const ExternalLinkIcon = ({ fill }: Partial<SvgParamTypes>) => (
  <svg xmlns="http://www.w3.org/2000/svg" width="37" height="36" viewBox="0 0 37 36" fill="none">
    <path
      d="M24.5 12.5148V21.4387C24.5 21.5752 24.4458 21.7062 24.3492 21.8027C24.2527 21.8993 24.1217 21.9535 23.9852 21.9535C23.8486 21.9535 23.7177 21.8993 23.6211 21.8027C23.5246 21.7062 23.4703 21.5752 23.4703 21.4387V13.7573L13.3658 23.8618C13.2682 23.9528 13.1391 24.0023 13.0058 23.9999C12.8724 23.9976 12.7451 23.9435 12.6508 23.8492C12.5565 23.7549 12.5024 23.6276 12.5001 23.4942C12.4977 23.3609 12.5472 23.2318 12.6382 23.1342L22.7427 13.0297H15.0613C14.9248 13.0297 14.7938 12.9754 14.6973 12.8789C14.6007 12.7823 14.5465 12.6514 14.5465 12.5148C14.5465 12.3783 14.6007 12.2473 14.6973 12.1508C14.7938 12.0542 14.9248 12 15.0613 12H23.9852C24.1217 12 24.2527 12.0542 24.3492 12.1508C24.4458 12.2473 24.5 12.3783 24.5 12.5148Z"
      fill={fill || "white"}
    />
  </svg>
);

export const CloseIcon = ({ fill }: Partial<SvgParamTypes>) => (
  <svg xmlns="http://www.w3.org/2000/svg" width="10" height="10" viewBox="0 0 10 10" fill="none">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M0.52827 0.528514C0.788619 0.268165 1.21073 0.268165 1.47108 0.528514L4.99967 4.05711L8.52827 0.528514C8.78862 0.268165 9.21073 0.268165 9.47108 0.528514C9.73143 0.788864 9.73143 1.21097 9.47108 1.47132L5.94248 4.99992L9.47108 8.52851C9.73143 8.78886 9.73143 9.21097 9.47108 9.47132C9.21073 9.73167 8.78862 9.73167 8.52827 9.47132L4.99967 5.94273L1.47108 9.47132C1.21073 9.73167 0.78862 9.73167 0.52827 9.47132C0.267921 9.21097 0.267921 8.78886 0.52827 8.52851L4.05687 4.99992L0.52827 1.47132C0.26792 1.21097 0.26792 0.788864 0.52827 0.528514Z"
      fill={fill || "#777E91"}
    />
  </svg>
);

export const ArrowLeftSm = ({ fill }: Partial<SvgParamTypes>) => (
  <svg xmlns="http://www.w3.org/2000/svg" width="11" height="14" viewBox="0 0 11 14" fill="none">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M1.05737 7.70698C0.807413 7.51945 0.666992 7.26514 0.666992 6.99998C0.666992 6.73481 0.807413 6.4805 1.05737 6.29298L8.60004 0.635977C8.72304 0.540467 8.87016 0.464284 9.03283 0.411875C9.19551 0.359466 9.37047 0.33188 9.54751 0.330726C9.72454 0.329572 9.90012 0.354874 10.064 0.405155C10.2278 0.455436 10.3767 0.529689 10.5019 0.623582C10.6271 0.717475 10.7261 0.829126 10.7931 0.952023C10.8602 1.07492 10.8939 1.2066 10.8924 1.33938C10.8908 1.47216 10.8541 1.60338 10.7842 1.72538C10.7143 1.84739 10.6127 1.95773 10.4854 2.04998L3.88537 6.99998L10.4854 11.95C10.7283 12.1386 10.8626 12.3912 10.8596 12.6534C10.8566 12.9156 10.7163 13.1664 10.4691 13.3518C10.2219 13.5372 9.8875 13.6424 9.53791 13.6447C9.18831 13.6469 8.85151 13.5461 8.60004 13.364L1.05737 7.70698Z"
      fill={fill || "white"}
    />
  </svg>
);

export const SyncTheBeatLogo = ({ fill }: Partial<SvgParamTypes>) => (
  <svg width="225" height="24" viewBox="0 0 225 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M39.7841 11.823L38.0881 10.8061C37.4554 10.4281 36.9855 10.0647 36.6842 9.71596C36.3829 9.37308 36.2323 8.95987 36.2323 8.47926C36.2323 7.94004 36.4281 7.52096 36.8228 7.21912C37.2144 6.91727 37.6753 6.76488 38.2025 6.76488C38.8804 6.76488 39.5672 7.00225 40.2631 7.47994C40.956 7.95762 41.4621 8.79576 41.7754 10.0002H42.2364L41.8116 6H41.4169C41.3657 6.20221 41.2964 6.35753 41.2061 6.46889C41.1157 6.58025 40.95 6.633 40.712 6.633C40.6246 6.633 40.2691 6.5275 39.6516 6.3165C39.031 6.1055 38.5008 6 38.0609 6C36.9945 6 36.1359 6.31357 35.4882 6.94364C34.8405 7.57372 34.5182 8.36204 34.5182 9.30861C34.5182 10.0237 34.7923 10.6772 35.3376 11.2692C35.6328 11.5798 36.0275 11.8904 36.5245 12.1952L38.1694 13.1945C39.1153 13.7689 39.7359 14.1997 40.0312 14.4869C40.483 14.9382 40.709 15.4716 40.709 16.0812C40.709 16.7581 40.4861 17.2885 40.0372 17.6666C39.5883 18.0446 39.0581 18.2351 38.4435 18.2351C37.3078 18.2351 36.3588 17.7985 35.5936 16.9252C35.1538 16.424 34.7712 15.7529 34.4459 14.9089H34L34.5754 18.9062H35.0002C35.0243 18.7538 35.0755 18.6102 35.1568 18.4754C35.2352 18.3406 35.3888 18.2732 35.6147 18.2732C35.7081 18.2732 36.0817 18.3846 36.7324 18.6044C37.3831 18.8242 38.0067 18.9355 38.6032 18.9355C39.7902 18.9355 40.7662 18.6044 41.5284 17.945C42.2906 17.2856 42.6732 16.4621 42.6732 15.4775C42.6732 14.7448 42.4593 14.1089 42.0285 13.5697C41.5917 13.0422 40.8445 12.456 39.7841 11.823Z"
      fill={fill || "white"}
    />
    <path
      d="M53.2505 6.61534C53.6783 6.6212 53.9796 6.65344 54.1543 6.70619C54.4495 6.79117 54.5971 6.96994 54.5971 7.23662C54.5971 7.2923 54.5791 7.37729 54.5459 7.49451C54.5128 7.61173 54.4375 7.75826 54.326 7.93409L51.5544 12.0515L48.7106 7.99857C48.578 7.80222 48.4937 7.6469 48.4575 7.52675C48.4184 7.40659 48.4003 7.30695 48.4003 7.22783C48.4003 6.96408 48.5148 6.7941 48.7407 6.71498C48.9666 6.63585 49.3071 6.60069 49.759 6.61534V6.2666H44.3574V6.61534C44.8605 6.63878 45.2883 6.82634 45.6378 7.17801C45.9902 7.52968 46.6018 8.32679 47.4754 9.56642L50 13.1681V16.4269C50 17.2445 49.8945 17.7544 49.6836 17.9596C49.4728 18.1647 48.9305 18.2907 48.0569 18.3405V18.6893H53.9464V18.3405C53.1059 18.2966 52.5636 18.1852 52.3196 18.0065C52.0786 17.8277 51.9581 17.3735 51.9581 16.6467V12.9952L54.808 8.76052C55.4045 7.88134 55.9407 7.27765 56.4167 6.95529C56.724 6.74721 57.0735 6.63585 57.4621 6.61534V6.2666H53.2445V6.61534H53.2505Z"
      fill={fill || "white"}
    />
    <path
      d="M66.9845 6.61534C67.6954 6.67688 68.1534 6.80583 68.3612 7.00804C68.6745 7.31868 68.8312 7.98684 68.8312 9.01254V15.2517L61.3811 6.2666H58.0884V6.61534C58.483 6.62706 58.7813 6.67102 58.9801 6.74721C59.1789 6.82341 59.4169 7.02269 59.7001 7.34798L59.9652 7.64983V15.9228C59.9652 16.9134 59.8507 17.5434 59.6248 17.816C59.3988 18.0885 58.8867 18.2643 58.0884 18.3435V18.6922H62.6223V18.3435C61.9264 18.2702 61.4745 18.1325 61.2757 17.9303C60.9654 17.6167 60.8117 16.9485 60.8117 15.9228V8.61399L69.3493 18.9091H69.6807V9.02427C69.6807 8.0396 69.8042 7.40952 70.0483 7.13698C70.2923 6.86151 70.7833 6.6886 71.5184 6.61534V6.2666H66.9845V6.61534Z"
      fill={fill || "white"}
    />
    <path
      d="M82.0743 17.4497C81.2308 17.9157 80.3119 18.1472 79.3118 18.1472C77.929 18.1472 76.7872 17.6461 75.8865 16.6467C74.9857 15.6474 74.5338 14.3111 74.5338 12.6348C74.5338 10.8149 74.9616 9.38479 75.8202 8.34444C76.6788 7.30409 77.7814 6.78538 79.134 6.78538C80.4716 6.78538 81.538 7.26892 82.3303 8.23308C82.7461 8.74593 83.0805 9.42289 83.3396 10.2581H83.7824L83.6047 6.04102H83.198C83.1347 6.24909 83.0232 6.40148 82.8575 6.49818C82.6949 6.59489 82.5201 6.64471 82.3394 6.64471C82.252 6.64471 81.8393 6.54507 81.1042 6.34286C80.3692 6.14065 79.6522 6.04102 78.9563 6.04102C76.9529 6.04102 75.3261 6.6916 74.0729 7.99277C72.886 9.2324 72.2925 10.768 72.2925 12.5938C72.2925 14.3785 72.8558 15.8672 73.9795 17.06C75.1996 18.3494 76.8445 18.9941 78.9171 18.9941C80.1794 18.9941 81.3513 18.6571 82.4328 17.986C83.0684 17.5875 83.5986 17.1274 84.0264 16.6028L83.6679 16.254C83.0895 16.7903 82.5563 17.1889 82.0743 17.4497Z"
      fill={fill || "white"}
    />
    <path
      d="M95.8723 6.2666L95.7578 9.45506H96.2278C96.4236 8.44694 96.7128 7.79343 97.0984 7.49451C97.484 7.19559 98.2131 7.04613 99.2795 7.04613H100.364V16.4327C100.364 17.2416 100.265 17.7456 100.063 17.9478C99.8609 18.1501 99.3518 18.279 98.5354 18.3405V18.6893H104.163V18.3405C103.377 18.2849 102.867 18.1706 102.635 17.9977C102.404 17.8248 102.286 17.3764 102.286 16.6525V7.04613H103.362C104.479 7.04613 105.214 7.19852 105.564 7.50037C105.913 7.80222 106.199 8.45574 106.425 9.45799H106.886L106.784 6.2666H95.8723Z"
      fill={fill || "white"}
    />
    <path
      d="M130.535 17.5376C129.911 17.816 128.947 17.9537 127.637 17.9537C126.429 17.9537 125.703 17.9215 125.462 17.8541C125.221 17.7867 125.097 17.5669 125.097 17.1947V12.7754H128.483C129.05 12.7754 129.511 13.2208 129.511 13.7747V14.6656H130.351V13.7747C130.351 13.2238 130.809 12.7754 131.378 12.7754V11.9578C130.812 11.9578 130.351 11.5123 130.351 10.9584V10.0675H129.511V10.9584C129.511 11.5094 129.053 11.9578 128.483 11.9578H125.097V7.61466C125.097 7.37143 125.13 7.20438 125.197 7.11647C125.263 7.02855 125.438 6.98459 125.721 6.98459H128.348C129.477 6.98459 130.206 7.10767 130.535 7.35091C130.86 7.59415 131.107 8.13044 131.27 8.95686H131.761L131.695 6.26953H121.44V6.61534C122.157 6.66516 122.618 6.79117 122.826 6.99631C123.034 7.20145 123.136 7.63518 123.136 8.30335V11.9607H119.075V8.30042C119.075 7.62932 119.18 7.18973 119.391 6.99045C119.602 6.78824 120.063 6.66223 120.771 6.61534V6.2666H115.418V6.61534C116.135 6.66516 116.596 6.78824 116.804 6.99045C117.011 7.19266 117.114 7.62932 117.114 8.30042V11.9578H111.251V8.30042C111.251 7.62932 111.357 7.18973 111.571 6.99045C111.785 6.78824 112.243 6.66223 112.947 6.61534V6.2666H107.597V6.61534C108.308 6.66516 108.766 6.78824 108.977 6.99045C109.188 7.19266 109.293 7.62932 109.293 8.30042V16.4327C109.293 17.2416 109.2 17.7427 109.01 17.9332C108.82 18.1237 108.35 18.2614 107.597 18.3405V18.6893H112.95V18.3405C112.246 18.2849 111.785 18.1618 111.574 17.9654C111.36 17.772 111.254 17.3324 111.254 16.6525V12.7754H117.117V16.4327C117.117 17.2416 117.023 17.7427 116.84 17.9391C116.653 18.1325 116.183 18.2673 115.421 18.3405V18.6893H120.774V18.3405C120.063 18.279 119.605 18.153 119.394 17.9625C119.183 17.772 119.078 17.3354 119.078 16.6555V12.7754H123.139V16.6525C123.139 17.3588 123.034 17.7984 122.823 17.9713C122.612 18.1442 122.151 18.2673 121.443 18.3405V18.6893H131.875L132.752 15.5184H132.216C131.716 16.5851 131.155 17.2592 130.535 17.5376Z"
      fill={fill || "white"}
    />
    <path
      d="M154.632 13.1974C154.003 12.7109 153.246 12.3651 152.361 12.157C153.078 11.9871 153.614 11.7673 153.972 11.5065C154.608 11.0464 154.924 10.3753 154.924 9.4873C154.924 8.30628 154.47 7.474 153.563 6.99045C152.656 6.50691 151.43 6.2666 149.887 6.2666H144.483V6.61534C145.257 6.65344 145.76 6.77066 145.989 6.96701C146.218 7.16629 146.333 7.61173 146.333 8.30042V16.6525C146.333 17.3529 146.218 17.7984 145.989 17.9889C145.76 18.1794 145.26 18.2966 144.483 18.3405V18.6893H150.773C151.846 18.6893 152.749 18.5457 153.484 18.2585C154.879 17.7075 155.575 16.7024 155.575 15.2371C155.575 14.3608 155.262 13.6809 154.632 13.1974ZM148.291 7.61466C148.291 7.31575 148.339 7.13112 148.432 7.06372C148.526 6.99631 148.776 6.96115 149.176 6.96115C150.237 6.96115 151.029 7.06958 151.55 7.28351C152.493 7.6762 152.963 8.43229 152.963 9.55177C152.963 10.5013 152.554 11.1607 151.737 11.527C151.309 11.7175 150.773 11.8171 150.125 11.8288L148.288 11.8552V7.61466H148.291ZM152.361 17.4496C151.695 17.8218 150.87 18.0094 149.881 18.0094C149.198 18.0094 148.761 17.9625 148.571 17.8687C148.381 17.7749 148.288 17.5639 148.288 17.2416V12.5644C148.45 12.5703 148.571 12.5732 148.649 12.5732C148.728 12.5732 148.914 12.5732 149.21 12.5732C150.415 12.5732 151.409 12.7666 152.189 13.1505C152.969 13.5344 153.358 14.2524 153.358 15.3045C153.361 16.3624 153.027 17.0775 152.361 17.4496Z"
      fill={fill || "white"}
    />
    <path
      d="M180.7 16.6907L175.892 6.04688H175.506L171.484 15.2606C170.882 16.6409 170.457 17.4878 170.207 17.7955C169.96 18.1032 169.586 18.2849 169.089 18.3406V18.6893H172.915V18.3406C172.463 18.3054 172.156 18.2498 171.993 18.1765C171.728 18.0534 171.599 17.8366 171.599 17.5201C171.599 17.3618 171.647 17.1479 171.746 16.8783C171.843 16.6086 172.144 15.8584 172.644 14.6305H177.729L178.501 16.4357C178.615 16.6907 178.702 16.9339 178.766 17.1655C178.829 17.397 178.859 17.5581 178.859 17.649C178.859 17.9215 178.778 18.0944 178.615 18.1648C178.452 18.2351 178.088 18.2937 177.525 18.3435V18.6923H182.432V18.3435C181.98 18.282 181.658 18.1677 181.465 18.0007C181.275 17.8307 181.019 17.394 180.7 16.6907ZM172.97 13.8656L175.193 8.69611L177.404 13.8656H172.97Z"
      fill={fill || "white"}
    />
    <path
      d="M191.898 6.2666H180.983L180.869 9.45799H181.339C181.534 8.44987 181.824 7.79636 182.209 7.49744C182.595 7.19852 183.324 7.04906 184.39 7.04906H185.475V16.4327C185.475 17.2416 185.375 17.7456 185.174 17.9478C184.972 18.1501 184.463 18.279 183.646 18.3405V18.6893H189.274V18.3405C188.487 18.2849 187.978 18.1706 187.746 17.9977C187.514 17.8248 187.397 17.3764 187.397 16.6525V7.04613H188.472C189.59 7.04613 190.325 7.19852 190.672 7.50037C191.021 7.80222 191.307 8.45574 191.533 9.45799H191.994L191.898 6.2666Z"
      fill={fill || "white"}
    />
    <path
      d="M166.327 17.5376C165.703 17.816 164.739 17.9537 163.429 17.9537C162.221 17.9537 161.495 17.9215 161.254 17.8541C161.013 17.7867 160.889 17.5669 160.889 17.1947V12.7754H164.275C164.842 12.7754 165.303 13.2208 165.303 13.7747V14.6656H166.143V13.7747C166.143 13.2238 166.601 12.7754 167.17 12.7754V11.9578C166.604 11.9578 166.143 11.5123 166.143 10.9584V10.0675H165.303V10.9584C165.303 11.5094 164.845 11.9578 164.275 11.9578H160.889V7.61466C160.889 7.37142 160.922 7.20438 160.989 7.11647C161.055 7.02855 161.23 6.98459 161.513 6.98459H164.143C165.272 6.98459 166.001 7.10767 166.33 7.35091C166.655 7.59415 166.902 8.13044 167.065 8.95686H167.556L167.49 6.26953H157.235V6.61534C157.952 6.66516 158.413 6.79117 158.621 6.99631C158.829 7.20145 158.931 7.63518 158.931 8.30335V16.6555C158.931 17.3617 158.825 17.8013 158.615 17.9742C158.404 18.1471 157.943 18.2702 157.235 18.3435V18.6922H167.667L168.544 15.5213H168.008C167.511 16.5851 166.95 17.2592 166.327 17.5376Z"
      fill={fill || "white"}
    />
  </svg>
);

export const AppName = () => {
  return (
    <svg width="241" height="52" viewBox="0 0 241 52" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M89.0897 48.62C88.5136 50.4321 88.4146 50.7798 90.4246 50.7798C92.3179 50.7798 93.026 50.6986 94.0335 49.0667C94.1985 49.016 94.3634 49.0489 94.3964 49.1657C94.2162 49.7266 93.6376 50.8965 93.3432 51.3407C92.7341 51.3229 92.0743 51.3077 91.4144 51.3077C90.7546 51.2924 90.1125 51.2924 89.5364 51.2924H87.6254C86.9503 51.2924 86.4046 51.3077 85.8793 51.3407C85.7473 51.2569 85.7473 51.0285 85.8641 50.9295L86.26 50.8813C87.0518 50.7671 87.2142 50.6021 87.7421 48.9195L89.6709 42.7905C90.1658 41.2424 90.1988 40.9785 89.2927 40.8465L88.8968 40.7805C88.7979 40.6486 88.8156 40.4354 88.9958 40.3694C89.6379 40.3846 90.1988 40.4176 90.8738 40.4176C91.5819 40.4176 92.0946 40.3846 92.9498 40.3694C93.0666 40.4354 93.0996 40.6993 92.9498 40.7805L92.4397 40.8465C91.5007 40.9633 91.417 41.1587 90.9068 42.7905L89.0897 48.62Z"
        fill="white"
      />
      <path
        d="M101.581 45.5569C101.581 46.8081 99.9492 47.6329 97.5763 48.2928C97.4595 48.5719 97.3453 49.0998 97.3453 49.7596C97.3453 50.3205 97.6905 50.6987 98.1371 50.6987C98.731 50.6987 99.1421 50.3687 99.868 49.7419C100.015 49.7419 100.114 49.8739 100.081 50.0211C99.0762 51.257 98.1371 51.5209 97.4443 51.5209C96.4545 51.5209 96.1094 50.5972 96.1094 49.7901C96.1094 48.7521 96.7692 46.7421 98.5635 45.359C99.4213 44.6991 100.063 44.5671 100.541 44.5671C101.2 44.5671 101.581 45.128 101.581 45.5569ZM98.9594 45.8691C98.3173 46.5619 97.9392 47.3182 97.7412 47.7979C98.8274 47.4679 99.3401 47.1558 99.9492 46.5797C100.459 46.1026 100.591 45.6077 100.591 45.4579C100.591 45.293 100.508 45.0798 100.211 45.0798C99.9822 45.0798 99.5533 45.227 98.9594 45.8691Z"
        fill="white"
      />
      <path
        d="M111.055 42.4429C111.451 41.174 111.304 41.0268 110.56 40.976L110.017 40.9608C109.9 40.8796 109.933 40.6486 110.017 40.5649C110.611 40.4837 111.565 40.3669 112.786 40.3669C113.659 40.3669 114.664 40.4329 115.456 40.7629C116.263 41.108 116.857 41.7349 116.857 42.8388C116.857 44.5037 115.192 45.2777 114.286 45.3437C114.022 45.359 114.139 45.4757 114.286 45.5239C115.225 45.8361 115.999 46.7599 115.999 47.9451C115.999 48.5542 115.819 49.3942 115.108 50.087C114.415 50.7799 113.197 51.3382 111.154 51.3382C110.263 51.3382 109.654 51.29 108.961 51.29C108.304 51.29 107.743 51.3052 107.266 51.3382C107.134 51.2545 107.134 51.0261 107.251 50.9271L107.581 50.8789C108.373 50.7647 108.535 50.5997 109.063 48.9171L111.055 42.4429ZM110.149 49.3967C109.801 50.483 110.281 50.8306 111.466 50.8306C112.849 50.8306 114.58 49.9728 114.58 47.7496C114.58 46.3487 113.377 45.6737 112.141 45.6737C111.349 45.6737 111.283 45.6889 111.119 46.2345L110.149 49.3967ZM111.598 44.6991C111.484 45.095 111.499 45.1762 112.207 45.1762C113.392 45.1762 115.501 44.6001 115.501 42.5241C115.501 41.5674 114.776 40.8771 113.425 40.8771C112.801 40.8771 112.768 40.8593 112.618 41.4049L111.598 44.6991Z"
        fill="white"
      />
      <path
        d="M120.648 46.133L119.94 48.209H120.021C120.516 47.2852 121.323 45.9681 121.752 45.4249C122.13 44.9301 122.544 44.5671 122.955 44.5671C123.531 44.5671 123.615 45.128 123.615 45.3742C123.615 45.6204 123.465 46.1 123.267 46.364C123.069 46.6101 122.805 46.6279 122.739 46.2802C122.658 45.8513 122.559 45.6381 122.394 45.6381C122.244 45.6381 122.064 45.8031 121.818 46.133C120.813 47.4197 119.889 49.4272 119.016 51.323C118.95 51.455 118.869 51.5209 118.737 51.5209C118.557 51.5209 118.077 51.455 118.194 51.092C118.407 50.4322 119.448 47.5162 119.892 45.9021C119.94 45.7219 119.907 45.5569 119.76 45.5569C119.331 45.5569 118.887 46.0188 118.59 46.4147C118.425 46.4477 118.311 46.3487 118.326 46.1508C118.854 45.26 119.481 44.5697 120.27 44.5697C121.092 44.5671 120.795 45.6889 120.648 46.133Z"
        fill="white"
      />
      <path
        d="M129.732 44.189C130.011 44.0571 130.44 44.222 130.539 44.4352C130.293 45.0443 128.859 48.6862 128.331 50.1861C128.232 50.4652 128.316 50.5642 128.448 50.5642C128.727 50.5642 129.206 50.285 130.146 49.4602C130.262 49.4602 130.376 49.5414 130.376 49.6734C129.684 50.5972 128.514 51.5184 127.608 51.5184C127.229 51.5184 127.131 51.2063 127.131 51.0743C127.131 50.9576 127.163 50.8104 127.295 50.4322L127.839 48.9501L127.79 48.9323C126.62 50.3815 124.94 51.5184 124.164 51.5184C123.704 51.5184 123.44 51.0566 123.44 50.5947C123.44 50.2165 123.803 48.8968 124.89 47.3995C125.681 46.3132 126.389 45.6534 127.065 45.2727C127.707 44.9098 128.118 44.7956 128.547 44.7956C128.729 44.7956 128.844 44.8463 128.943 44.8946C129.009 44.9276 129.189 45.0748 129.272 45.2397L129.732 44.189ZM126.864 46.0671C125.811 47.0061 124.803 49.1658 124.803 50.3358C124.803 50.5337 124.902 50.648 125.052 50.648C125.217 50.648 125.712 50.4348 126.651 49.577C127.902 48.407 128.595 46.9909 128.859 46.0189C128.742 45.7067 128.463 45.425 128.034 45.425C127.638 45.425 127.377 45.6052 126.864 46.0671Z"
        fill="white"
      />
      <path
        d="M135.945 45.5088C135.945 45.7727 135.78 45.9529 135.648 46.0189C135.531 46.0519 135.434 46.0189 135.335 45.8869C135.056 45.6052 134.841 45.359 134.33 45.359C133.853 45.359 133.655 45.7702 133.655 46.0189C133.655 46.364 133.737 46.7117 134.216 47.5188C134.627 48.2116 134.874 48.6228 134.843 49.3486C134.828 49.7927 134.597 50.4044 133.985 50.9297C133.556 51.3078 132.947 51.5236 132.32 51.5236C131.694 51.5236 131.315 51.0287 131.315 50.6505C131.315 50.4856 131.381 50.3054 131.562 50.1074C131.694 49.9907 131.874 49.9907 132.006 50.1074C132.219 50.3536 132.714 50.7673 133.143 50.7673C133.539 50.7673 133.935 50.3536 133.935 49.8283C133.935 49.5491 133.737 49.0364 133.325 48.3791C132.897 47.6863 132.749 47.1279 132.749 46.8462C132.749 46.2042 133.028 45.7245 133.739 45.1154C134.216 44.7017 134.744 44.5723 134.957 44.5723C135.564 44.5672 135.945 45.0951 135.945 45.5088Z"
        fill="white"
      />
      <path
        d="M141.086 45.5088C141.086 45.7727 140.921 45.9529 140.789 46.0189C140.674 46.0519 140.573 46.0189 140.476 45.8869C140.197 45.6052 139.982 45.359 139.471 45.359C138.994 45.359 138.796 45.7702 138.796 46.0189C138.796 46.364 138.88 46.7117 139.357 47.5188C139.771 48.2116 140.017 48.6228 139.984 49.3486C139.966 49.7927 139.738 50.4044 139.126 50.9297C138.697 51.3078 138.088 51.5236 137.464 51.5236C136.84 51.5236 136.459 51.0287 136.459 50.6505C136.459 50.4856 136.525 50.3054 136.705 50.1074C136.837 49.9907 137.02 49.9907 137.149 50.1074C137.362 50.3536 137.857 50.7673 138.286 50.7673C138.682 50.7673 139.078 50.3536 139.078 49.8283C139.078 49.5491 138.88 49.0364 138.469 48.3791C138.04 47.6863 137.893 47.1279 137.893 46.8462C137.893 46.2042 138.172 45.7245 138.883 45.1154C139.36 44.7017 139.888 44.5723 140.101 44.5723C140.705 44.5672 141.086 45.0951 141.086 45.5088Z"
        fill="white"
      />
      <path
        d="M143.987 49.8739C143.837 50.2698 143.921 50.4348 144.101 50.4348C144.347 50.4348 145.007 49.9069 145.865 49.0669C147.365 47.5847 148.22 45.6737 148.766 44.6687C149.111 44.6027 149.507 44.7677 149.573 44.9656C149.408 45.4098 148.337 48.1126 147.644 50.0744C147.56 50.2876 147.545 50.4855 147.71 50.4855C148.022 50.4855 148.898 49.7115 149.324 49.2014C149.471 49.1684 149.603 49.2344 149.621 49.4323C149.192 50.2241 147.611 51.5261 146.738 51.5261C146.474 51.5261 146.228 51.2139 146.327 50.95L147.446 47.935L147.413 47.9172C146.225 49.4171 144.182 51.5261 143.294 51.5261C142.619 51.5261 142.538 50.851 142.817 50.1252L144.332 46.1204C144.446 45.8082 144.464 45.6433 144.332 45.6433C144.017 45.6433 143.162 46.3691 142.766 46.7802C142.601 46.8132 142.469 46.6812 142.487 46.534C142.916 45.8742 144.563 44.5723 145.337 44.5723C145.55 44.5723 145.931 44.7854 145.832 45.0672L143.987 49.8739Z"
        fill="white"
      />
      <path
        d="M155.225 45.5088C155.225 45.7727 155.06 45.9529 154.928 46.0189C154.811 46.0519 154.715 46.0189 154.616 45.8869C154.334 45.6052 154.121 45.359 153.611 45.359C153.134 45.359 152.936 45.7702 152.936 46.0189C152.936 46.364 153.019 46.7117 153.497 47.5188C153.91 48.2116 154.156 48.6228 154.123 49.3486C154.108 49.7927 153.877 50.4044 153.266 50.9297C152.837 51.3078 152.228 51.5236 151.601 51.5236C150.974 51.5236 150.596 51.0287 150.596 50.6505C150.596 50.4856 150.662 50.3054 150.842 50.1074C150.974 49.9907 151.154 49.9907 151.286 50.1074C151.499 50.3536 151.997 50.7673 152.423 50.7673C152.819 50.7673 153.215 50.3536 153.215 49.8283C153.215 49.5491 153.017 49.0364 152.606 48.3791C152.177 47.6863 152.03 47.1279 152.03 46.8462C152.03 46.2042 152.311 45.7245 153.019 45.1154C153.497 44.7017 154.024 44.5723 154.238 44.5723C154.844 44.5672 155.225 45.0951 155.225 45.5088Z"
        fill="white"
      />
      <path
        d="M31.8044 6.75774C33.0048 6.75774 34.0454 7.98608 34.0454 9.91995V25.066C34.0454 30.6291 31.7587 30.1697 29.3528 30.1697C26.9469 30.1697 26.6652 27.7866 26.6652 25.033V9.92248C26.6652 7.98608 27.7057 6.76028 28.9062 6.76028V5.97607H20.7139V6.76028C21.9143 6.76028 23.0005 7.98862 23.0005 9.92248V26.2969C23.0005 29.4566 24.7085 31.799 28.7082 31.799C32.7104 31.799 35.1646 31.5884 35.1646 24.9569V9.92248C35.1646 7.98608 36.2051 6.76028 37.4005 6.76028V5.97607H31.8044V6.75774Z"
        fill="white"
      />
      <path
        d="M205.17 6.75774C206.373 6.75774 207.325 7.98608 207.325 9.91995V25.066C207.325 30.6291 205.038 30.1697 202.632 30.1697C200.229 30.1697 199.945 27.7866 199.945 25.033V9.92248C199.945 7.98608 200.942 6.76028 202.143 6.76028V5.97607H194.077V6.76028C195.278 6.76028 196.278 7.98862 196.278 9.92248V26.2969C196.278 29.4566 197.985 31.799 201.985 31.799C205.987 31.799 208.442 31.5884 208.442 24.9569V9.92248C208.442 7.98608 209.393 6.76028 210.591 6.76028V5.97607H205.17V6.75774Z"
        fill="white"
      />
      <path
        d="M48.3037 30.0074C47.9763 30.0962 46.5652 30.2536 45.413 30.147C44.4689 30.0607 44.4207 29.9236 44.4207 27.835V9.69671C44.4207 7.60803 44.9461 7.10045 46.6388 7.10045C48.73 7.10045 49.6462 7.70447 50.3492 9.5622C51.745 13.1508 51.5217 19.8279 51.1309 24.9037C51.1309 24.9012 50.6055 29.3755 48.3037 30.0074ZM47.7428 5.97363H38.5557V6.75784C39.7586 6.75784 40.756 7.98618 40.756 9.92004V27.3883C40.756 29.3272 39.7586 30.5454 38.5557 30.5454V31.3246H48.8569C50.5192 31.3246 52.4252 30.3729 53.6028 26.9873C54.7524 23.4698 54.8895 20.6045 54.7296 16.1912C54.5113 11.8184 53.8007 9.1536 52.7729 7.82883C51.7476 6.50913 50.1005 5.97363 47.7428 5.97363Z"
        fill="white"
      />
      <path
        d="M62.9548 30.1318C61.7773 29.9846 61.6402 29.4618 61.6402 27.5609V19.196C64.2898 19.4193 64.5385 19.4117 65.2161 22.9064H65.9292V14.1837H65.2161C64.6425 17.4906 64.2923 17.6682 61.6402 17.8915V9.36425C61.6402 7.46337 61.7976 7.12837 62.6275 7.1639L64.1603 7.24004C67.7794 7.38723 67.9672 9.56474 68.1981 10.8311H69.1067V5.97363H55.7295V6.75784C56.9299 6.75784 57.9704 7.98618 57.9704 9.92004V27.3883C57.9704 29.3272 56.9274 30.5454 55.7295 30.5454V31.3246H69.2742L70.2868 26.7284H69.2615C68.0687 30.8271 64.7694 30.1927 62.9548 30.1318Z"
        fill="white"
      />
      <path
        d="M218.925 30.1318C217.75 29.9846 217.611 29.4618 217.611 27.5609V19.196C220.26 19.4219 220.509 19.4117 221.187 22.9064H221.897V14.1837H221.187C220.611 17.4906 220.26 17.6682 217.611 17.8915V9.36425C217.611 7.46337 217.771 7.12837 218.598 7.1639L220.131 7.24004C223.752 7.38723 223.94 9.56474 224.169 10.8311H225.08V5.97363H211.743V6.75784C212.946 6.75784 213.941 7.98618 213.941 9.92004V27.3883C213.941 29.3272 212.944 30.5454 211.743 30.5454V31.3246H225.245L226.257 26.7284H225.232C224.039 30.8297 220.74 30.1927 218.925 30.1318Z"
        fill="white"
      />
      <path
        d="M14.2959 20.4294H7.95119L14.2959 4.77315V20.4294ZM18.3895 0.484131H11.423C11.423 0.484131 11.423 1.05262 11.423 1.28864C13.2604 1.28864 13.4305 2.64387 12.8442 4.13107C10.5043 10.052 4.59865 25.0102 3.90834 26.538C3.04546 28.4491 1.83235 30.5479 0 30.5479C0 30.9667 0 31.327 0 31.327H6.5985C6.5985 31.327 6.5985 31.0352 6.5985 30.5479C5.09607 30.5479 4.64687 28.3501 5.393 26.5457C6.1366 24.7412 7.44361 21.5587 7.44361 21.5587C7.44361 21.5587 7.52736 21.5587 14.2959 21.5587C14.2959 21.5587 14.2959 22.1272 14.2959 27.0507C14.2959 30.3906 13.1082 30.5479 11.4281 30.5479C11.4281 30.8905 11.4281 30.0124 11.4281 31.327C11.4281 31.327 10.9332 31.327 21.2675 31.327C21.2675 31.327 21.2675 31.0631 21.2675 30.5479C19.8691 30.5479 18.4022 30.4464 18.4022 27.0507C18.4022 25.2869 18.3895 0.484131 18.3895 0.484131Z"
        fill="white"
      />
      <path
        d="M163.021 15.2368C163.021 16.0667 162.724 16.5057 162.115 16.5057H158.808L158.81 9.76766V8.21447L158.808 1.60836H162.115C162.724 1.60836 163.021 2.09056 163.021 3.06257C163.021 3.06257 163.021 14.4069 163.021 15.2368ZM163.808 0.479004C163.769 0.479004 163.747 0.479004 163.742 0.479004H151.788C151.788 0.479004 151.788 0.885065 151.788 1.29113C153.506 1.29113 154.663 2.4484 154.663 4.73504C154.663 10.9732 154.663 25.2944 154.663 26.7512C154.663 30.2636 153.349 30.5453 151.793 30.5453C151.793 30.9742 151.793 31.3244 151.793 31.3244C154.4 31.3244 157.143 31.3244 161.633 31.3244C161.633 31.3244 161.633 31.0224 161.633 30.5453C159.856 30.5453 158.808 30.157 158.808 26.7689C158.808 25.0153 158.808 21.8759 158.808 17.6782H164.059C165.749 17.6782 167.061 16.4524 167.061 13.6658V4.31882C167.061 2.09564 165.962 0.479004 163.808 0.479004Z"
        fill="white"
      />
      <path
        d="M82.682 23.6981L77.7839 5.97607H71.3174V6.76028C72.9442 6.76028 73.6015 7.31608 73.6015 9.99608V27.3933C73.6015 29.3322 72.6726 30.5504 71.3529 30.5504V31.3295H77.0581V30.5504C75.7384 30.5504 74.807 29.3322 74.807 27.3933V9.14082L81.0248 31.327H81.6339L88.0776 9.21949V27.3907C88.0776 29.3297 87.1462 30.5479 85.8265 30.5479V31.327H94.105V30.5479C92.7879 30.5479 91.8083 29.3297 91.8083 27.3907V9.92248C91.8083 7.98608 92.7853 6.76028 94.105 6.76028V5.97607H87.8745L82.7734 23.2921"
        fill="white"
      />
      <path
        d="M122.156 15.6861C122.156 18.7214 118.938 18.3001 117.811 18.3407V8.77038C117.811 7.7603 117.849 7.24004 118.756 7.24004C120.479 7.24004 122.156 6.95579 122.156 9.33125C122.156 10.3616 122.156 12.6533 122.156 15.6861ZM126.811 29.2156L122.59 19.2417C123.928 18.927 125.626 18.4118 125.626 16.0719C125.626 13.5492 125.567 10.8895 125.567 9.12315C125.567 5.97363 122.677 5.97363 119.715 5.97363H111.951V6.75784C113.149 6.75784 114.142 7.98618 114.142 9.92004V27.3883C114.142 29.3222 112.85 30.5454 111.913 30.5454C111.863 30.5454 111.982 30.5454 111.913 30.5454V31.3246H120.05V30.5454C118.857 30.5454 117.811 29.3272 117.811 27.3883V19.531H119.022L123.207 29.9389C123.207 29.9389 123.42 30.5581 123.844 30.9718C124.245 31.3626 125.06 31.3271 125.06 31.3271H128.085V30.548C127.808 30.548 127.455 30.7408 126.811 29.2156Z"
        fill="white"
      />
      <path
        d="M169.259 6.75774C170.759 6.75774 171.454 7.98608 171.454 9.91995V27.3882C171.454 29.3271 170.759 30.5453 169.259 30.5453V31.3245H177.309V30.5453C175.809 30.5453 175.121 29.3271 175.121 27.3882V9.92248C175.121 7.98608 175.809 6.76028 177.309 6.76028V5.97607H169.259V6.75774Z"
        fill="white"
      />
      <path
        d="M136.465 15.6937C133.67 14.242 132.604 12.3412 132.604 10.7042C132.604 9.80836 132.98 6.50911 135.213 6.50911C138.614 6.54717 139.675 11.3666 140.048 13.4731H140.715V5.58532H139.969C139.934 6.4482 139.614 6.65884 139.211 6.65884C137.947 6.62077 137.569 5.14119 134.277 5.28077C132.145 5.3696 129.485 7.23494 129.485 12.298C129.485 14.6075 130.374 17.8509 133.216 19.7822C137.35 22.5815 138.667 23.6957 138.667 25.8529C138.667 26.7487 138.409 30.4972 135.109 30.4972C132.777 30.4972 131.239 26.6599 130.937 25.2082L130.404 22.6018H129.658V31.4768H130.429C130.506 30.7687 130.693 30.383 131 30.383C132.216 30.383 133.465 31.6798 135.622 31.6519C141.099 31.5809 142.071 26.9416 142.071 24.3352C142.071 20.7568 140.365 17.7037 136.465 15.6937Z"
        fill="white"
      />
      <path
        d="M187.857 18.5183V19.1756C187.857 19.1756 188.969 19.2771 188.969 21.0461C188.969 22.2871 188.969 25.0838 188.969 26.9771C188.969 30.1444 187.877 30.3982 185.943 30.3982C184.01 30.3982 183.243 29.6875 183.243 27.2664V9.79815C183.243 8.14345 184.096 6.72985 186.139 6.72985C188.4 6.72985 190.821 8.99871 190.821 13.3842H192.055V5.58018H191.126C191.126 8.56727 189.354 5.4939 186.24 5.4939C183.982 5.4939 182.119 6.07507 181.058 7.27803C179.974 8.49621 179.479 9.97072 179.479 11.6965V25.4061C179.479 27.1344 179.974 28.5963 181.058 29.7891C182.137 31.0301 184.499 31.6519 186.035 31.6519C186.931 31.6519 188.21 31.3219 188.854 31.0072C189.497 30.6951 190.088 30.2357 190.618 29.6317C191.722 28.3907 192.27 26.9289 192.27 25.2488C192.27 25.2488 192.27 22.2744 192.27 21.0029C192.27 19.3152 193.382 19.1756 193.382 19.1756V18.5183H187.857Z"
        fill="white"
      />
      <path
        d="M109.551 28.8043L104.848 5.88721H104.13H102.231H100.31C100.31 5.88721 100.31 6.45569 100.31 6.69172C101.891 6.69172 101.668 7.97081 101.379 9.55191L97.2318 28.081C96.7217 30.1849 96.0542 30.5478 95.4248 30.5478V31.327H99.7291V30.5478C98.1226 30.5478 98.0997 29.2408 98.5616 27.2866L99.5438 23.594H104.749L105.741 28.1521C106.249 30.1849 105.442 30.5453 104.467 30.5453V31.3244H110.997V30.5453C111 30.5453 111.005 30.5453 111.005 30.5453C110.609 30.5377 109.947 30.3753 109.551 28.8043ZM99.795 22.3961L102.285 10.9985L104.401 22.3961H99.795Z"
        fill="white"
      />
      <path
        d="M226.745 5.97363V10.8311H227.583C227.697 9.86421 227.91 8.43284 228.791 7.86943C229.671 7.30602 230.575 7.33901 232.037 7.33901V27.3883C232.037 29.3272 231.087 30.5454 229.884 30.5454V31.3246H237.902V30.5454C236.701 30.5454 235.704 29.3272 235.704 27.3883V7.3847C235.704 7.3847 235.704 7.36186 235.704 7.34155C237.166 7.34155 238.069 7.30602 238.95 7.87197C239.83 8.43538 240.046 9.86675 240.16 10.8337H240.998V5.97363H226.745Z"
        fill="white"
      />
    </svg>
  );
};
