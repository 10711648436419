import classNames from "classnames";
import useGlobalStore from "../store/globalStore";
import { ArrowLeftSm } from "../svg";

const INFORMATION_DATA = [
  {
    id: 1,
    text: "蒐集個人資料公司：主辦單位(台灣愛彼錶股份有限公司)及受委託廠商(電通邁格瑞博恩股份有限公司及洋薊企画有限公司)（以下合稱本公司）",
  },
  {
    id: 2,
    text: "蒐集之目的：本次行銷活動，及未來相關消費者、客戶管理服務，與日後本公司商品或服務相關之再行銷用途。",
  },
  {
    id: 3,
    text: "個人資料之類別：包括姓名、電子郵件、聯絡方式、通訊地址等個人資料中之識別類（具體項目則如本活動參與頁面所列事項）。",
  },
  {
    id: 4,
    text: "個人資料利用之期間、地區、對象及方式：",
    keyPoints: [
      {
        id: 1,
        text: "期間：個人資料蒐集之特定目的存續期間、依相關法令或契約約定之保存期限，或本公司為執行業務或前述蒐集之目的所必需之保存期間。",
      },
      {
        id: 2,
        text: "地區：本國、未受中央目的事業主管機關限制之國際傳輸個人資料之接收者所在地。",
      },
      {
        id: 3,
        text: "對象：本公司及執行本活動時之必要相關人員。",
      },
      {
        id: 4,
        text: "方式：以自動化機器或其他非自動化之利用方式。",
      },
    ],
  },
  {
    id: 5,
    text: " 依據個資法第三條規定，本活動參加者瞭解，就其所提供之個人資料得行使下列權利：",
    keyPoints: [
      {
        id: 1,
        text: "得向本公司查詢或請求閱覽、請求製給複製本，惟本公司依法得酌收必要成本費用。",
      },
      {
        id: 2,
        text: "得向本公司請求補充或更正，惟依法本活動參加者應為適當之釋明。",
      },
      {
        id: 3,
        text: "得向本公司請求停止蒐集、處理或利用以及請求刪除，惟依法本公司因執行職務或保存證據所必須者，得不依本活動參加者請求為之。",
      },
      {
        id: 4,
        text: "不提供個人資料所致權益之影響：本活動參加者可自由選擇是否提供本公司其個人資料，若拒絕提供相關個人資料，則無法參與本活動；且經檢舉或本公司發現不足以確認本活動參加者的身分真實性或其他個人資料冒用、盜用、資料不實等情形，致本公司無法進行必要之確認作業，本公司有權暫時停止提供本活動相關服務，若有不便之處敬請見諒。",
      },
    ],
  },
];

const Information = () => {
  const { isInformationOpen, setInformationOpen } = useGlobalStore();
  return (
    <div className={classNames("sidebar p-8 !h-screen overflow-y-auto !top-0", { open: isInformationOpen, close: !isInformationOpen })}>
      {isInformationOpen ? (
        <div>
          <div className="flex items-center gap-2 mb-[18px] noto" onClick={() => setInformationOpen(false)}>
            <span>
              <ArrowLeftSm />
            </span>{" "}
            返回
          </div>
          <h1 className="text-white text-[32px] font-bold leading-10 mb-4 dm-sans">隱私權聲明</h1>
          <p className="text-[#95A1BB] mb-4 text-sm font-normal leading-6 poppins">最後更新：2024/08/09</p>
          <div className="text-white text-sm leading-6 font-normal text-justify noto">
            <p className="mb-3">
              依據個人資料保護法（以下稱個資法）向本活動參加者告知下列事項，參與本活動前請務必詳閱以下說明；於參加本活動時，即視為瞭解並同意接受下列事項。
            </p>
            <div className="mb-3">
              {INFORMATION_DATA.map((data) => (
                <div key={data.id}>
                  <div className="list-decimal list-inside">
                    <p className="mb-2">
                      <span className="inline-block mr-1">{data.id}.</span>
                      {data.text}
                    </p>
                    {data.keyPoints && data.keyPoints.length && data.keyPoints && (
                      <ul className="list-disc list-item ml-7 mb-3">
                        {data.keyPoints.map((keyPoint) => (
                          <li key={keyPoint.id}>{keyPoint.text}</li>
                        ))}
                      </ul>
                    )}
                  </div>
                </div>
              ))}
            </div>
            <p>
              活動參加者瞭解本公司上開告知事項，並已清楚瞭解本公司蒐集、處理及利用活動參加者個人資料之目的及用途，並同意本公司於上開告知事項範圍內，得蒐集、處理、國際傳遞及利用活動參加者資料。
            </p>
          </div>
        </div>
      ) : null}
    </div>
  );
};

export default Information;
