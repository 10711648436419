import classNames from "classnames";
import { Link } from "react-router-dom";
import { NAVIGATION } from "../constants/static";
import useGlobalStore from "../store/globalStore";

const Footer = () => {
  const { setInformationOpen } = useGlobalStore();
  return (
    <footer className="w-full bg-white p-8 h-[400px] fixed bottom-0 -z-1">
      <div className="relative">
        {NAVIGATION.map((link) => (
          <Link
            to={link.link}
            key={link.id}
            className={classNames("text-xs text-black font-normal leading-5 block mb-[14px] poppins", {
              "flex justify-between items-center gap-3": link.id === NAVIGATION.length,
            })}
          >
            {link.title}
          </Link>
        ))}

        <span
          className="absolute bottom-0 right-0 z-100 text-xs text-black font-normal leading-5 block underline inter"
          onClick={(e) => {
            e.stopPropagation();
            setInformationOpen(true);
          }}
        >
          隱私權聲明
        </span>
      </div>
      <div className="w-full h-[54px] my-[37px]">
        <img src="/images/footer/logo.svg" alt="artichke" className="w-full h-full object-contain" />
      </div>
      <p className="text-black text-xs font-normal leading-[22px] dm-sans uppercase">Copyright © 2024. All rights reserved.</p>
    </footer>
  );
};

export default Footer;
