import classNames from "classnames";
import React from "react";

type CustomButtonProps = {
  children: React.ReactNode;
  onClick?: () => void;
  className?: string;
  isSubmitButton?: boolean;
};

const CustomButton = ({ children, onClick, className, isSubmitButton = false }: CustomButtonProps) => {
  return (
    <button
      type={isSubmitButton ? "submit" : "button"}
      onClick={onClick}
      className={classNames(className, "dm-sans bg-lite-grey w-full rounded-full py-2 px-4 text-base font-normal text-black text-center")}
    >
      {children}
    </button>
  );
};

export default CustomButton;
