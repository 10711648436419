import dayjs from "dayjs";

const enableTimeDayjs = dayjs("2024-09-04T00:01:00.000+08:00");
export const enableTime = enableTimeDayjs.toDate();

export const allowedChipUid = [
  "042D29A2FD1290",
  "042729A2FD1290",
  "042828A2FD1290",
  "042D28A2FD1290",
  "043028A2FD1290",
  "047D24A2FD1290",
  // Debug Chips:
  "04B323A2FD1290",
  "04B423A2FD1290",
  "04AC24A2FD1290",
  "04AB24A2FD1290",
  "04AB24A2FD1290",

  "047526A2FD1290",
  "046126A2FD1290",
  "044626A2FD1290",
  "044226A2FD1290",
  "046B27A2FD1290",
  "049627A2FD1290",
  "04B128A2FD1290",
  "047F28A2FD1290",
  "047428A2FD1290",
  "047D24A2FD1290",
  "043028A2FD1290",
  "042D28A2FD1290",
  "042828A2FD1290",
  "042729A2FD1290",
  "042D29A2FD1290",
  "047F2AA2FD1290",
  "04662AA2FD1290",
  "04B323A2FD1290",
  "04B423A2FD1290",
  "04B523A2FD1290",
  "04B524A2FD1290",
  "04B424A2FD1290",
  "04B324A2FD1290",
  "04B224A2FD1290",
  "04B124A2FD1290",
  "04B024A2FD1290",
  "04AF24A2FD1290",
  "04AE24A2FD1290",
  "04AD24A2FD1290",

  "042C29A2FD1290",
  "046628A2FD1290",
  "046428A2FD1290",

  "047127A2FD1290",
  "049627A2FD1290",
  "04B027A2FD1290",
  "04B528A2FD1290",
  "04B128A2FD1290",
  "047428A2FD1290",
  "047F2AA2FD1290",
  "04672AA2FD1290",
  "04662AA2FD1290",
  "04652AA2FD1290",
  "044C26A2FD1290",
  "044626A2FD1290",
  "044226A2FD1290",
  "048C28A2FD1290",
  "047526A2FD1290",
  "046C26A2FD1290",
  "046126A2FD1290",
  "048528A2FD1290",
  "046B27A2FD1290",
  "047F28A2FD1290",
  "042D29A2FD1290",
  "042729A2FD1290",
  "042828A2FD1290",
  "042D28A2FD1290",
  "043028A2FD1290",
  "047D24A2FD1290",
];
